import store from '@/state/store'

export default [
  {
    path: '/auth/login',
    name: 'auth.login',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/auth/login_beta'),
  },

  {
    path: '/dashboard/:campId',
    name: 'dashboard.byCamp',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/dashboard/indexByCamp'),
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/dashboard/index'),
  },

  {
    path: '/',
    name: 'dashboard',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/dashboard/index'),
  },

  {
    path: '/service_catalog',
    name: 'service_catalog',
    meta: {
      authRequired: true,
      permissions: ['create_mr',
        'edit_mr',
        'delete_mr',
        'view_mr',
        'list_mr',
        'create_vsr',
        'edit_vsr',
        'delete_vsr',
        'view_vsr',
        'list_vsr',
        'create_tamr',
        'edit_tamr',
        'delete_tamr',
        'view_tamr',
        'list_tamr',
        'create_dr',
        'edit_dr',
        'delete_dr',
        'view_dr',
        'list_dr',
        'create_maintenance_order',
        'edit_maintenance_order',
        'delete_maintenance_order',
        'view_maintenance_order',
        'list_maintenance_order',
        'create_hosting_booking',
        'update_hosting_booking',
        'read_hosting_booking',
        'delete_hosting_booking',
        'list_hosting_booking',
        'create_rest_ods',
        'edit_rest_ods',
        'delete_rest_ods',
        'view_rest_ods',
        'list_rest_ods',
        'create_host_ods',
        'edit_host_ods',
        'delete_host_ods',
        'view_host_ods',
        'list_host_ods',
        'create_gs_ods',
        'edit_gs_ods',
        'delete_gs_ods',
        'view_gs_ods',
        'list_gs_ods',
        'edit_threedops_ods',
        'view_threedops_ods',
        'list_threedops_ods',
        'delete_threedops_ods',
        'create_threedops_ods',
        'create_stock_entry',
        'edit_stock_entry',
        'delete_stock_entry',
        'view_stock_entry',
        'list_stock_entry',
        'create_stock_exit',
        'edit_stock_exit',
        'delete_stock_exit',
        'view_stock_exit',
        'list_stock_exit',
      ],
    },
    component: () => import('./views/service_catalog/index'),
  },
  {
    path: '/service_catalog/personels_transport',
    name: 'service_catalog.personels_transport',
    meta: {
      authRequired: true,
      permissions: ['personal_transp_log', 'personal_transp_log_approve_entry', 'personal_transp_log_approve_exit'],
    },
    component: () => import('./views/service_catalog/transport/index'),
  },


  {
    path: '/service_catalog/maintenance/index',
    name: 'service_catalog.maintenance.index',
    meta: {
      authRequired: true,
      // permissions: ['system_settings_crud']
    },
    component: () => import('./views/service_catalog/Maintenance/index'),
  },

  {
    path: '/service_catalog/custom_services',
    name: 'service_catalog.custom_services',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/custom_service/index'),
  },
  {
    path: '/service_catalog/custom_services/ods',
    name: 'service_catalog.custom_services.ods',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/custom_service/ods/index'),
  },
  {
    path: '/service_catalog/custom_services/reception',
    name: 'service_catalog.custom_services.reception',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/custom_service/reception/index'),
  },
  {
    path: '/service_catalog/custom_services/validation',
    name: 'service_catalog.custom_services.validation',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/custom_service/validation/index'),
  },
  {
    path: '/service_catalog/restauration/rating',
    name: 'service_catalog.restauration.rating',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/rating/index'),
  },

  {
    path: '/service_catalog/restauration/rating/report',
    name: 'service_catalog.restauration.rating.report',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/rating/report'),
  },

  {
    path: '/service_catalog/restauration',
    name: 'service_catalog.restauration',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/index'),
  },

  {
    path: '/service_catalog/restauration/ods',
    name: 'service_catalog.restauration.ods',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/ods/index'),
  },
  {
    path: '/service_catalog/restauration/reception',
    name: 'service_catalog.restauration.reception',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/reception/index'),
  },
  {
    path: '/service_catalog/restauration/validation',
    name: 'service_catalog.restauration.validation',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/validation/index'),
  },

  {
    path: '/service_catalog/restauration/exports',
    name: 'service_catalog.restauration.exports',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Restauration/exports/index'),
  },



  {
    path: '/service_catalog/cleaning&Maintenance',
    name: 'service_catalog.cleaning&Maintenance',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/Entretien&Nettoyage/index'),
  },
  {
    path: '/service_catalog/green_areas',
    name: 'service_catalog.green_areas',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/EspacesVerts/index'),
  },
  {
    path: '/service_catalog/green_areas/exports',
    name: 'service_catalog.green_areas.exports',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/EspacesVerts/exports'),
  },
  {
    path: '/service_catalog/green_areas/ods',
    name: 'service_catalog.green_areas.ods',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/EspacesVerts/ods/index'),
  },
  {
    path: '/service_catalog/green_areas/reception',
    name: 'service_catalog.green_areas.reception',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/EspacesVerts/reception/index'),
  },
  {
    path: '/service_catalog/green_areas/validation',
    name: 'service_catalog.green_areas.validation',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/EspacesVerts/validation/index'),
  },

  {
    path: '/service_catalog/access',
    name: 'service_catalog.access',
    meta: {
      authRequired: true,
      permissions: ['isp_acces_log', 'isp_acces_log_approve_entry', 'isp_acces_log_approve_exit'],
    },
    component: () => import('./views/service_catalog/access/index'),
  },

  {
    path: '/service_catalog/stock',
    name: 'service_catalog.stock',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/stock/index'),
  },

  {
    path: '/service_catalog/stock/moves/:op',
    name: 'service_catalog.stock.moves',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/stock/stock_moves/index'),
  },

  {
    path: '/service_catalog/hse',
    name: 'service_catalog.hse',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/hse/index'),
  },
  {
    path: '/service_catalog/hse/criterias',
    name: 'service_catalog.hse.criterias',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/hse/criterias/index'),
  },
  {
    path: '/service_catalog/hse/activities',
    name: 'service_catalog.hse.activities',
    meta: {
      authRequired: true,
      permissions:[
        'create_activity',
        'edit_activity',
        'delete_activity',
        'view_activity',
        'list_activity'
      ]
    },
    component: () => import('./views/service_catalog/hse/activities/index'),
  },
  {
    path: '/service_catalog/hse/trashes',
    name: 'service_catalog.hse.trashes',
    meta: {
      authRequired: true,
      permissions:[
        'create_trash',
        'edit_trash',
        'delete_trash',
        'view_trash',
        'list_trash'
      ]
    },
    component: () => import('./views/service_catalog/hse/trashs/index'),
  },
  {
    path: '/service_catalog/hse/gsactivities',
    name: 'service_catalog.hse.gsactivities',
    meta: {
      authRequired: true,
      permissions:[
        'create_trash',
        'edit_trash',
        'delete_trash',
        'view_trash',
        'list_trash'
      ]
    },
    component: () => import('./views/service_catalog/hse/gs_actions/index'),
  },
  {
    path: '/service_catalog/hse/activities/list',
    name: 'service_catalog.hse.activities.list',
    meta: {
      authRequired: true,
      permissions:[
        'create_activity',
        'edit_activity',
        'delete_activity',
        'view_activity',
        'list_activity',
      ]
    },
    component: () => import('./views/service_catalog/hse/activities/new/index'),
  },
  {
    path: '/service_catalog/hse/activities/report',
    name: 'service_catalog.hse.activities.report',
    meta: {
      authRequired: true,
      permissions:[
        'hse_activity_confirm',
        'hse_activity_approve',
        'hse_activity_reject'
      ]
    },
    component: () => import('./views/service_catalog/hse/activities/resume/index'),
  },
  {
    path: '/service_catalog/hse/trashes/list',
    name: 'service_catalog.hse.trashes.list',
    meta: {
      authRequired: true,
      permissions:[
        'create_trash',
        'edit_trash',
        'delete_trash',
        'view_trash',
        'list_trash'
      ]
    },
    component: () => import('./views/service_catalog/hse/trashs/new/index'),
  },
  {
    path: '/service_catalog/hse/trashes/report',
    name: 'service_catalog.hse.trashes.report',
    meta: {
      authRequired: true,
      permissions:[
        'hse_trash_confirm',
        'hse_trash_approve',
        'hse_trash_reject',
      ]
    },
    component: () => import('./views/service_catalog/hse/trashs/resume/index'),
  },

  {
    path: '/service_catalog/hse/gsactivities/list',
    name: 'service_catalog.hse.gsactivities.list',
    meta: {
      authRequired: true,
      permissions:[
        'create_trash',
        'edit_trash',
        'delete_trash',
        'view_trash',
        'list_trash'
      ]
    },
    component: () => import('./views/service_catalog/hse/gs_actions/new/index'),
  },

  {
    path: '/service_catalog/hse/:category',
    name: 'service_catalog.hse.index.category',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/hse/criterias/controls/index_beta'),
  },

  {
    path: '/service_catalog/operations',
    name: 'service_catalog.operations',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/operations/index'),
  },
  {
    path: '/service_catalog/operations/ods',
    name: 'service_catalog.operations.ods',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/operations/ods'),
  },
  {
    path: '/service_catalog/operations/reception',
    name: 'service_catalog.operations.reception',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/operations/reception'),
  },
  {
    path: '/service_catalog/operations/validation',
    name: 'service_catalog.operations.validation',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/service_catalog/operations/validation'),
  },


  {
    path: '/monitoring_and_management',
    name: 'monitoring_and_management',
    meta: {
      authRequired: true,
      permissions: ['create_inv_att', 'edit_inv_att', 'delete_inv_att', 'view_inv_att', 'list_inv_att', 'create_supp_inv', 'edit_supp_inv', 'delete_supp_inv', 'view_supp_inv', 'list_supp_inv'],
    },
    component: () => import('./views/monitoring_and_management/index'),
  },

  {
    path: '/monitoring_and_management/contract',
    name: 'monitoring_and_management.contract',
    meta: {
      authRequired: true,
      permissions: ['db_view_contract_dashboard'],
    },
    component: () => import('./views/monitoring_and_management/contract_monitoring/index'),
  },

  {
    path: '/monitoring_and_management/contract/view/:uid',
    name: 'monitoring_and_management.contract.dashboard',
    meta: {
      authRequired: true,
      permissions: ['db_view_contract_dashboard'],
    },
    component: () => import('./views/monitoring_and_management/contract_monitoring/dashboard'),
  },


  {
    path: '/monitoring_and_management/suppliers_invoices',
    name: 'monitoring_and_management.suppliers_invoices',
    meta: {
      authRequired: true,
      permissions: ["create_supp_inv", "edit_supp_inv", "delete_supp_inv", "view_supp_inv", "list_supp_inv"],
    },
    component: () => import('./views/monitoring_and_management/suppliers_invoices/index'),
  },

  {
    path: '/monitoring_and_management/suppliers_invoices/contract/:uid/invoices',
    name: 'invoicing.contract.suppliers_invoices',
    meta: {
      authRequired: true,
      permissions: ["list_supp_inv"],
    },
    component: () => import('./views/monitoring_and_management/suppliers_invoices/contractInvoices'),
  },

  {
    path: '/suppliers_invoices/view_invoice/:uid',
    name: 'suppliers_invoices.display',
    meta: {
      authRequired: true,
      permissions: ["view_supp_inv"],
    },
    component: () => import('./views/monitoring_and_management/suppliers_invoices/display'),
  },

  {
    path: '/monitoring_and_management/invoicing',
    name: 'invoicing',
    meta: {
      authRequired: true,
      permissions: ["create_inv_att", "edit_inv_att", "delete_inv_att", "view_inv_att", "list_inv_att"],
    },
    component: () => import('./views/invoicing/index'),
  },

  {
    path: '/monitoring_and_management/invoicing/contract/:uid/invoices',
    name: 'invoicing.contract.invoices',
    meta: {
      authRequired: true,
      permissions: ["list_inv_att",],
    },
    component: () => import('./views/invoicing/contractInvoices'),
  },

  {
    path: '/invoicing/view_invoice/:uid',
    name: 'invoicing.display',
    meta: {
      authRequired: true,
      permissions: ["view_inv_att",],
    },
    component: () => import('./views/invoicing/display'),
  },

  {
    path: '/master_data',
    name: 'master_data',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/master_data/index'),
  },
  {
    path: '/master_data/referentiels',
    name: 'master_data.referentiels',
    meta: {
      authRequired: true,
      permissions: [
        "create_supplier",
        "edit_supplier",
        "delete_supplier",
        "view_supplier",
        "list_supplier",
        "create_hierarchie",
        "update_hierarchie",
        "read_hierarchie",
        "delete_hierarchie",
        "list_hierarchie",
        "create_infra",
        "update_infra",
        "read_infra",
        "delete_infra",
        "list_infra",
        "create_employee",
        "edit_employee",
        "delete_employee",
        "view_employee",
        "list_employee",
        "create_equipment",
        "edit_equipment",
        "delete_equipment",
        "view_equipment",
        "list_equipment",
        "create_product",
        "edit_product",
        "delete_product",
        "view_product",
        "list_product",
        "create_product_categ",
        "edit_product_categ",
        "delete_product_categ",
        "view_product_categ",
        "list_product_categ",
        "create_hosting_room",
        "update_hosting_room",
        "read_hosting_room",
        "delete_hosting_room",
        "list_hosting_room",
        "create_kitchen",
        "update_kitchen",
        "read_kitchen",
        "delete_kitchen",
        "list_kitchen",
        "create_animal",
        "edit_animal",
        "delete_animal",
        "view_animal",
        "list_animal",
        "create_plant",
        "edit_plant",
        "delete_plant",
        "view_plant",
        "list_plant",
        "create_equipment",
        "edit_equipment",
        "delete_equipment",
        "view_equipment",
        "list_equipment",
        "create_aac",
        "edit_aac",
        "delete_aac",
        "view_aac",
        "list_aac",

      ]
    },
    component: () => import('./views/master_data/referentiels'),
  },
  {
    path: '/master_data/referentiels/index',
    name: 'master_data.referentiels.index',
    meta: {
      authRequired: true,
      permissions: [
        "create_hierarchie",
        "update_hierarchie",
        "read_hierarchie",
        "delete_hierarchie",
        "list_hierarchie",
      ]
    },
    component: () => import('./views/base/hierarchy/index'),
  },
  {
    path: '/master_data/referentiels/users',
    name: 'master_data.referentiels.users',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/master_data/users/index'),
  },
  {
    path: '/master_data/referentiels/infrastructure',
    name: 'master_data.referentiels.infrastructure',
    meta: {
      authRequired: true,
      permissions: [
        "create_infra",
        "update_infra",
        "read_infra",
        "delete_infra",
        "list_infra",
      ]
    },
    component: () => import('./views/base/infrastructure/index'),
  },

  {
    path: '/master_data/referentiels/intra_entreprise',
    name: 'master_data.referentiels.intra_entreprise',
    meta: {
      authRequired: true,
      permissions: [
        "create_ie",
        "update_ie",
        "read_ie",
        "delete_ie",
        "list_ie",
      ]
    },
    component: () => import('./views/master_data/users/intra_entreprise/index'),
  },

  {
    path: '/master_data/referentiels/company',
    name: 'master_data.referentiels.company',
    meta: {
      authRequired: true,
      permissions: [
        "create_extc",
        "update_extc",
        "read_extc",
        "delete_extc",
        "list_extc",
      ]
    },
    component: () => import('./views/master_data/users/company/index'),
  },
  {
    path: '/master_data/referentiels/visitors',
    name: 'master_data.referentiels.visitors',
    meta: {
      authRequired: true,
      permissions: [
        "create_visitor",
        "edit_visitor",
        "delete_visitor",
        "view_visitor",
        "list_visitor",
      ]
    },
    component: () => import('./views/master_data/users/visitors/index'),
  },
  {
    path: '/master_data/referentiels/internes_employes',
    name: 'master_data.referentiels.internes_employes',
    meta: {
      authRequired: true,
      permissions: [
        "create_visitor",
        "edit_visitor",
        "delete_visitor",
        "view_visitor",
        "list_visitor",
      ]
    },
    component: () => import('./views/master_data/users/internes_employes/index'),
  },
  {
    path: '/master_data/referentiels/employes',
    name: 'master_data.referentiels.employes',
    meta: {
      authRequired: true,
      permissions: [
        "create_employee",
        "edit_employee",
        "delete_employee",
        "view_employee",
        "list_employee",
      ]
    },
    component: () => import('./views/master_data/users/employes/index'),
  },
  {
    path: '/master_data/referentiels/prestataires',
    name: 'master_data.referentiels.prestataires',
    meta: {
      authRequired: true,
      permissions: [
        "create_supplier",
        "edit_supplier",
        "delete_supplier",
        "view_supplier",
        "list_supplier",
      ]
    },
    component: () => import('./views/master_data/users/prestataires/index'),
  },
  {
    path: '/master_data/referentiels/analytics',
    name: 'master_data.referentiels.analytics',
    meta: {
      authRequired: true,
      permissions: [
        "create_aac",
        "edit_aac",
        "delete_aac",
        "view_aac",
        "list_aac",
      ]
    },
    component: () => import('./views/master_data/analytics'),
  },
  {
    path: '/master_data/referentiels/stock_management',
    name: 'master_data.referentiels.stock_management',
    meta: {
      authRequired: true,
      permissions: [
        "create_equipment",
        "edit_equipment",
        "delete_equipment",
        "view_equipment",
        "list_equipment",
        "create_product",
        "edit_product",
        "delete_product",
        "view_product",
        "list_product",
        "create_product_categ",
        "edit_product_categ",
        "delete_product_categ",
        "view_product_categ",
        "list_product_categ",
        'create_warehouse',
        'edit_warehouse',
        'delete_warehouse',
        'view_warehouse',
        'list_warehouse'
      ]
    },
    component: () => import('./views/master_data/stock'),
  },
  {
    path: '/master_data/referentiels/analytics/:typeAnalytic',
    name: 'master_data.referentiels.analytics.index',
    meta: {
      authRequired: true,
      permissions: [
        "create_aac",
        "edit_aac",
        "delete_aac",
        "view_aac",
        "list_aac",
      ]
    },
    props: true,
    component: () => import('./views/master_data/analytics/index'),
  },
  {
    path: '/requests',
    name: 'requests',
    meta: {
      authRequired: true,
      permissions: ['create_mr', 'edit_mr', 'delete_mr', 'view_mr', 'list_mr', 'create_vsr',
        'edit_vsr',
        'delete_vsr',
        'view_vsr',
        'list_vsr', 'create_tamr',
        'edit_tamr',
        'delete_tamr',
        'view_tamr',
        'list_tamr', 'create_dr',
        'edit_dr',
        'delete_dr',
        'view_dr',
        'list_dr',],
    },
    component: () => import('./views/userRequests/index'),
  },

  {
    path: '/maintenance/maintenance_orders',
    name: 'maintenanceOrders.index',
    meta: {
      authRequired: true,
      permissions: ['create_maintenance_order',
        'edit_maintenance_order',
        'delete_maintenance_order',
        'view_maintenance_order',
        'list_maintenance_order',]
    },
    component: () => import('./views/ops/maintenanceOrders/index'),
  },
  {
    path: '/maintenance/maintenance_follows',
    name: 'maintenance_follows.index',
    meta: {
      authRequired: true,
      permissions: ['create_maintenance_order',
        'edit_maintenance_order',
        'delete_maintenance_order',
        'view_maintenance_order',
        'list_maintenance_order',]
    },
    component: () => import('./views/ops/maintenance_follows/index'),
  },

  {
    path: '/maintenance_orders/view/:uid',
    name: 'maintenanceOrders.view',
    meta: {
      authRequired: true,
      permissions: ['view_maintenance_order']
    },
    component: () => import('./views/ops/maintenanceOrders/display'),
  },

  {
    path: '/requests/maintenance',
    name: 'requests.maintenance.index',
    meta: {
      authRequired: true,
      permissions: ['create_mr', 'edit_mr', 'delete_mr', 'view_mr', 'list_mr',]
    },
    component: () => import('./views/userRequests/maintenance/index'),
  },

  {
    path: '/requests/maintenance/new',
    name: 'requests.maintenance.new',
    meta: {
      authRequired: true,
      permissions: ['create_mr']
    },
    component: () => import('./views/userRequests/maintenance/new'),
  },

  {
    path: '/requests/maintenance/view/:uid',
    name: 'requests.maintenance.display',
    meta: {
      authRequired: true,
      permissions: ['view_mr']
    },
    component: () => import('./views/userRequests/maintenance/display'),
  },

  {
    path: '/requests/takeawaymeals',
    name: 'requests.takeawaymeals.index',
    meta: {
      authRequired: true,
      permissions: ['create_tamr', 'edit_tamr', 'delete_tamr', 'view_tamr', 'list_tamr',]
    },
    component: () => import('./views/userRequests/takeawaymeals/index'),
  },

  {
    path: '/requests/takeawaymeals/new',
    name: 'requests.takeawaymeals.new',
    meta: {
      authRequired: true,
      permissions: ['create_tamr']
    },
    component: () => import('./views/userRequests/takeawaymeals/new'),
  },

  {
    path: '/requests/takeawaymeals/view/:uid',
    name: 'requests.takeawaymeals.display',
    meta: {
      authRequired: true,
      permissions: ['creview_tamrate_tamr']
    },
    component: () => import('./views/userRequests/takeawaymeals/display'),
  },

  {
    path: '/requests/takeawaymeals/edit/:uid',
    name: 'requests.takeawaymeals.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_tamr']
    },
    component: () => import('./views/userRequests/takeawaymeals/edit'),
  },

  {
    path: '/requests/dotations',
    name: 'requests.dotations.index',
    meta: {
      authRequired: true,
      permissions: ['create_dr',
        'edit_dr',
        'delete_dr',
        'view_dr',
        'list_dr',]
    },
    component: () => import('./views/userRequests/dotations/index'),
  },

  {
    path: '/requests/visitors_support',
    name: 'requests.visitors_support.index',
    meta: {
      authRequired: true,
      permissions: ['create_vsr',
        'edit_vsr',
        'delete_vsr',
        'view_vsr',
        'list_vsr',]
    },
    component: () => import('./views/userRequests/visitorsSupport/index'),
  },

  {
    path: '/requests/visitors_support/new',
    name: 'requests.visitors_support.new',
    meta: {
      authRequired: true,
      permissions: ['create_vsr']
    },
    component: () => import('./views/userRequests/visitorsSupport/new'),
  },

  {
    path: '/requests/visitors_support/edit/:uid',
    name: 'requests.visitors_support.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_vsr']
    },
    component: () => import('./views/userRequests/visitorsSupport/edit'),
  },

  {
    path: '/requests/visitors_support/view/:uid',
    name: 'requests.visitors_support.display',
    meta: {
      authRequired: true,
      permissions: ['view_vsr']
    },
    component: () => import('./views/userRequests/visitorsSupport/display'),
  },

  {
    path: '/hierarchy/directions',
    name: 'hierarchy.directions.index',
    meta: {
      authRequired: true,
      permissions: ["create_administration_direction",
        "update_administration_direction",
        "read_administration_direction",
        "delete_administration_direction",
        "list_administration_direction"]
    },
    component: () => import('./views/base/hierarchy/directions/index'),
  },

  {
    path: '/hierarchy/directions/new',
    name: 'hierarchy.directions.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_direction']
    },
    component: () => import('./views/base/hierarchy/directions/new'),
  },

  {
    path: '/hierarchy/directions/edit/:uid',
    name: 'hierarchy.directions.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_direction']
    },
    component: () => import('./views/base/hierarchy/directions/edit'),
  },

  {
    path: '/hierarchy/divisions',
    name: 'hierarchy.divisions.index',
    meta: {
      authRequired: true,
      permissions: ['create_administration_division',
        'update_administration_division',
        'read_administration_division',
        'delete_administration_division',
        'list_administration_division',]
    },
    component: () => import('./views/base/hierarchy/divisions/index'),
  },

  {
    path: '/hierarchy/divisions/new',
    name: 'hierarchy.divisions.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_division'],
    },
    component: () => import('./views/base/hierarchy/divisions/new'),
  },

  {
    path: '/hierarchy/divisions/edit/:uid',
    name: 'hierarchy.divisions.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_division'],
    },
    component: () => import('./views/base/hierarchy/divisions/edit'),
  },

  {
    path: '/hierarchy/departments',
    name: 'hierarchy.departments.index',
    meta: {
      authRequired: true,
      permissions: ['create_administration_department',
        'update_administration_department',
        'read_administration_department',
        'delete_administration_department',
        'list_administration_department',]
    },
    component: () => import('./views/base/hierarchy/departments/index'),
  },

  {
    path: '/hierarchy/departments/new',
    name: 'hierarchy.departments.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_department']
    },
    component: () => import('./views/base/hierarchy/departments/new'),
  },

  {
    path: '/hierarchy/departments/edit/:uid',
    name: 'hierarchy.departments.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_department']
    },
    component: () => import('./views/base/hierarchy/departments/edit'),
  },

  {
    path: '/hierarchy/services',
    name: 'hierarchy.services.index',
    meta: {
      authRequired: true,
      permissions: [
        'create_administration_service',
        'update_administration_service',
        'read_administration_service',
        'delete_administration_service',
        'list_administration_service',
      ]
    },
    component: () => import('./views/base/hierarchy/services/index'),
  },

  {
    path: '/hierarchy/services/new',
    name: 'hierarchy.services.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_service']
    },
    component: () => import('./views/base/hierarchy/services/new'),
  },

  {
    path: '/hierarchy/services/edit/:uid',
    name: 'hierarchy.services.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_service']
    },
    component: () => import('./views/base/hierarchy/services/edit'),
  },

  {
    path: '/infrastructures',
    name: 'infrastructures',
    meta: {
      authRequired: true,
    },

  },
  {
    path: '/infrastructures/zones',
    name: 'base.infrastructures.zones',
    meta: {
      authRequired: true,
      permissions: ['create_administration_zone',
        'update_administration_zone',
        'read_administration_zone',
        'delete_administration_zone',
        'list_administration_zone',]
    },
    component: () => import('./views/base/infrastructure/zones/index'),
  },

  {
    path: '/infrastructures/zones/new',
    name: 'base.infrastructures.zones.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_zone']
    },
    component: () => import('./views/base/infrastructure/zones/new'),
  },

  {
    path: '/infrastructures/zones/edit/:uid',
    name: 'base.infrastructures.zones.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_zone']
    },
    component: () => import('./views/base/infrastructure/zones/edit'),
  },

  {
    path: '/infrastructures/sectors',
    name: 'base.infrastructures.sectors',
    meta: {
      authRequired: true,
      permissions: [
        'create_administration_sector',
        'update_administration_sector',
        'read_administration_sector',
        'delete_administration_sector',
        'list_administration_sector',
      ]
    },
    component: () => import('./views/base/infrastructure/sectors/index'),
  },

  {
    path: '/infrastructures/sectors/new',
    name: 'base.infrastructures.sectors.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_sector']
    },
    component: () => import('./views/base/infrastructure/sectors/new'),
  },

  {
    path: '/infrastructures/sectors/edit/:uid',
    name: 'base.infrastructures.sectors.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_sector']
    },
    component: () => import('./views/base/infrastructure/sectors/edit'),
  },

  {
    path: '/infrastructures/blocs',
    name: 'base.infrastructures.blocs',
    meta: {
      authRequired: true,
      permissions: [
        'create_administration_bloc',
        'update_administration_bloc',
        'read_administration_bloc',
        'delete_administration_bloc',
        'list_administration_bloc',
      ]
    },
    component: () => import('./views/base/infrastructure/blocs/index'),
  },

  {
    path: '/infrastructures/blocs/new',
    name: 'base.infrastructures.blocs.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_bloc']
    },
    component: () => import('./views/base/infrastructure/blocs/new'),
  },

  {
    path: '/infrastructures/blocs/edit/:uid',
    name: 'base.infrastructures.blocs.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_bloc']
    },
    component: () => import('./views/base/infrastructure/blocs/edit'),
  },


  {
    path: '/infrastructures/rooms',
    name: 'base.infrastructures.rooms',
    meta: {
      authRequired: true,
      permissions: [
        'create_hosting_room',
        'update_hosting_room',
        'read_hosting_room',
        'delete_hosting_room',
        'list_hosting_room',
      ]
    },
    component: () => import('./views/base/infrastructure/rooms/index'),
  },

  {
    path: '/infrastructures/rooms/new',
    name: 'base.infrastructures.rooms.new',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_room']
    },
    component: () => import('./views/base/infrastructure/rooms/new'),
  },

  {
    path: '/infrastructures/rooms/edit/:uid',
    name: 'base.infrastructures.rooms.edit',
    meta: {
      authRequired: true,
      permissions: ['update_hosting_room']
    },
    component: () => import('./views/base/infrastructure/rooms/edit'),
  },

  {
    path: '/infrastructures/offices',
    name: 'base.infrastructures.offices',
    meta: {
      authRequired: true,
      permissions: [
        'create_administration_office',
        'update_administration_office',
        'read_administration_office',
        'delete_administration_office',
        'list_administration_office',
      ]
    },
    component: () => import('./views/base/infrastructure/offices/index'),
  },

  {
    path: '/infrastructures/offices/new',
    name: 'base.infrastructures.offices.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_office']
    },
    component: () => import('./views/base/infrastructure/offices/new'),
  },

  {
    path: '/infrastructures/offices/edit/:uid',
    name: 'base.infrastructures.offices.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_office']
    },
    component: () => import('./views/base/infrastructure/offices/edit'),
  },

  {
    path: '/infrastructures/meeting_rooms',
    name: 'base.infrastructures.meeting_rooms',
    meta: {
      authRequired: true,
      permissions: [
        'create_administration_meeting_room',
        'update_administration_meeting_room',
        'read_administration_meeting_room',
        'delete_administration_meeting_room',
        'list_administration_meeting_room',
      ]
    },
    component: () => import('./views/base/infrastructure/meetingRooms/index'),
  },

  {
    path: '/infrastructures/meeting_rooms/new',
    name: 'base.infrastructures.meeting_rooms.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_meeting_room'],
    },
    component: () => import('./views/base/infrastructure/meetingRooms/new'),
  },

  {
    path: '/infrastructures/meeting_rooms/edit/:uid',
    name: 'base.infrastructures.meeting_rooms.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_meeting_room'],
    },
    component: () => import('./views/base/infrastructure/meetingRooms/edit'),
  },

  {
    path: '/infrastructures/kitchen',
    name: 'base.infrastructures.kitchen',
    meta: {
      authRequired: true,
      permissions: [
        'create_kitchen',
        'update_kitchen',
        'read_kitchen',
        'delete_kitchen',
        'list_kitchen',
      ]
    },
    component: () => import('./views/base/infrastructure/kitchen/index'),
  },

  {
    path: '/infrastructures/restaurants',
    name: 'base.infrastructures.restaurants',
    meta: {
      authRequired: true,
      permissions: [
        'create_administration_restaurant',
        'update_administration_restaurant',
        'read_administration_restaurant',
        'delete_administration_restaurant',
        'list_administration_restaurant',
      ]
    },
    component: () => import('./views/base/infrastructure/restaurants/index'),
  },

  {
    path: '/infrastructures/restaurants/new',
    name: 'base.infrastructures.restaurants.new',
    meta: {
      authRequired: true,
      permissions: ['create_administration_restaurant']
    },
    component: () => import('./views/base/infrastructure/restaurants/new'),
  },

  {
    path: '/infrastructures/restaurants/edit/:uid',
    name: 'base.infrastructures.restaurants.edit',
    meta: {
      authRequired: true,
      permissions: ['update_administration_restaurant']
    },
    component: () => import('./views/base/infrastructure/restaurants/edit'),
  },

  {
    path: '/misc/equipments',
    name: 'base.misc.equipments',
    meta: {
      authRequired: true,
      permissions: [
        'create_hosting_room_equipments',
        'update_hosting_room_equipments',
        'read_hosting_room_equipments',
        'delete_hosting_room_equipments',
        'list_hosting_room_equipments',
        'create_equipment',
        'edit_equipment',
        'delete_equipment',
        'view_equipment',
        'list_equipment'
      ]
    },
    component: () => import('./views/base/data/misc/equipments/index'),
  },

  {
    path: '/misc/equipments/new',
    name: 'base.misc.equipments.new',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_room_equipments']
    },
    component: () => import('./views/base/data/misc/equipments/new'),
  },

  {
    path: '/misc/equipments/edit/:uid',
    name: 'base.misc.equipments.edit',
    meta: {
      authRequired: true,
      permissions: ['update_hosting_room_equipments']
    },
    component: () => import('./views/base/data/misc/equipments/edit'),
  },

  {
    path: '/misc/projects',
    name: 'base.misc.projects',
    meta: {
      authRequired: true,
      permissions: [
        'create_project',
        'edit_project',
        'delete_project',
        'view_project',
        'list_project'
      ]
    },
    component: () => import('./views/base/data/misc/projects/index'),
  },

  {
    path: '/misc/projects/new',
    name: 'base.misc.projects.new',
    meta: {
      authRequired: true,
      permissions: ['create_project']
    },
    component: () => import('./views/base/data/misc/projects/new'),
  },

  {
    path: '/misc/projects/edit/:uid',
    name: 'base.misc.projects.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_project']
    },
    component: () => import('./views/base/data/misc/projects/edit'),
  },

  {
    path: '/master_data/referentiels/stock_management/products',
    name: 'base.stock.products',
    meta: {
      authRequired: true,
      permissions: [
        'create_product',
        'edit_product',
        'delete_product',
        'view_product',
        'list_product'
      ]
    },
    component: () => import('./views/base/data/stock/products/index'),
  },

  {
    path: '/stock/products/new',
    name: 'base.stock.products.new',
    meta: {
      authRequired: true,
      permissions: ['create_product'],
    },
    component: () => import('./views/base/data/stock/products/new'),
  },

  {
    path: '/stock/products/edit/:uid',
    name: 'base.stock.products.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_product'],
    },
    component: () => import('./views/base/data/stock/products/edit'),
  },

  {
    path: '/contracts/contracts',
    name: 'base.contracts.contracts.index',
    meta: {
      authRequired: true,
      permissions: [
        'create_contract',
        'edit_contract',
        'delete_contract',
        'view_contract',
        'list_contract'
      ]
    },
    component: () => import('./views/base/data/contract/contract/index_beta'),
  },

  {
    path: '/contracts/contracts/new',
    name: 'base.contracts.contracts.new',
    meta: {
      authRequired: true,
      permissions: ['create_contract']
    },
    component: () => import('./views/base/data/contract/contract/new_beta'),
  },

  {
    path: '/contracts/contracts/settings/:uid',
    name: 'base.contracts.contracts.settings',
    meta: {
      authRequired: true,
      permissions: ['edit_contract']
    },
    component: () => import('./views/base/data/contract/contract/settings'),
  },

  {
    path: '/contracts/contracts/edit/:uid',
    name: 'base.contracts.contracts.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_contract']
    },
    component: () => import('./views/base/data/contract/contract/edit'),
  },

  {
    path: '/contracts/contracts/display/:uid',
    name: 'base.contracts.contracts.display',
    meta: {
      authRequired: true,
      permissions: ['view_contract']
    },
    component: () => import('./views/base/data/contract/contract/display'),
  },

  {
    path: '/contracts/suppliers',
    name: 'base.contracts.suppliers.index',
    meta: {
      authRequired: true,
      permissions: [
        'create_supplier',
        'edit_supplier',
        'delete_supplier',
        'view_supplier',
        'list_supplier'
      ]
    },
    component: () => import('./views/base/data/contract/supplier/index_beta'),
  },

  {
    path: '/contracts/suppliers/new',
    name: 'base.contracts.suppliers.new',
    meta: {
      authRequired: true,
      permissions: ['create_supplier']
    },
    component: () => import('./views/base/data/contract/supplier/new'),
  },

  {
    path: '/contracts/suppliers/edit/:uid',
    name: 'base.contracts.suppliers.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_supplier']
    },
    component: () => import('./views/base/data/contract/supplier/edit'),
  },

  {
    path: '/contracts/suppliers/display/:uid',
    name: 'base.contracts.suppliers.display',
    meta: {
      authRequired: true,
      permissions: ['view_supplier']
    },
    component: () => import('./views/base/data/contract/supplier/display'),
  },

  {
    path: '/contracts/suppliers/edit/:uid',
    name: 'base.contracts.suppliers.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_supplier']
    },
    component: () => import('./views/base/data/contract/supplier/edit'),
  },

  {
    path: '/hr/employees',
    name: 'base.hr.employees',
    meta: {
      authRequired: true,
      permissions: [
        'create_employee',
        'edit_employee',
        'delete_employee',
        'view_employee',
        'list_employee'
      ]
    },
    component: () => import('./views/base/data/hr/employees/index'),
  },

  {
    path: '/hr/employees/new',
    name: 'base.hr.employees.new',
    meta: {
      authRequired: true,
      permissions: ['create_employee'],
    },
    component: () => import('./views/base/data/hr/employees/new'),
  },

  {
    path: '/hr/employees/edit/:uid',
    name: 'base.hr.employees.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_employee'],
    },
    component: () => import('./views/base/data/hr/employees/edit'),
  },

  {
    path: '/hr/employees_prestataire',
    name: 'base.hr.employees_prestataire',
    meta: {
      authRequired: true,
      permissions: [
        'create_employee',
        'edit_employee',
        'delete_employee',
        'view_employee',
        'list_employee'
      ]
    },
    component: () => import('./views/base/data/hr/employees_prestataire/index'),
  },

  {
    path: '/hr/employees_prestataire/new',
    name: 'base.hr.employees_prestataire.new',
    meta: {
      authRequired: true,
      permissions: ['create_employee'],
    },
    component: () => import('./views/base/data/hr/employees_prestataire/new'),
  },
  {
    path: '/hr/employees_prestataire/edit/:uid',
    name: 'base.hr.employees_prestataire.edit',
    meta: {
      authRequired: true,
      permissions: ['edit_employee'],
    },
    component: () => import('./views/base/data/hr/employees_prestataire/edit'),
  },

  {
    path: '/operations/hosting',
    name: 'ops.hosting.index',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking', 'update_hosting_booking', 'read_hosting_booking', 'delete_hosting_booking', 'list_hosting_booking']
    },
    component: () => import('./views/ops/hosting/index'),
  },

  {
    path: '/operations/hosting/ods',
    name: 'ops.hosting.ods.index',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking', 'update_hosting_booking', 'read_hosting_booking', 'delete_hosting_booking', 'list_hosting_booking']
    },
    component: () => import('./views/ops/hosting/ods/ods'),
  },
  {
    path: '/operations/hosting/ods/reception',
    name: 'ops.hosting.ods.reception.index',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking', 'update_hosting_booking', 'read_hosting_booking', 'delete_hosting_booking', 'list_hosting_booking']
    },
    component: () => import('./views/ops/hosting/ods/reception'),
  },
  {
    path: '/operations/hosting/ods/validation',
    name: 'ops.hosting.ods.validation.index',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking', 'update_hosting_booking', 'read_hosting_booking', 'delete_hosting_booking', 'list_hosting_booking']
    },
    component: () => import('./views/ops/hosting/ods/validation'),
  },

  {
    path: '/operations/hosting/bookings',
    name: 'ops.hosting.bookings',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking', 'update_hosting_booking', 'read_hosting_booking', 'delete_hosting_booking', 'list_hosting_booking']
    },
    component: () => import('./views/ops/hosting/booking/booking_beta_v2'),
  },

  {
    path: '/operations/hosting/plannings',
    name: 'ops.hosting.plannings',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking', 'update_hosting_booking', 'read_hosting_booking', 'delete_hosting_booking', 'list_hosting_booking']
    },
    component: () => import('./views/ops/hosting/plannings/index'),
  },

  

  {
    path: '/operations/hosting/bookings/new',
    name: 'ops.hosting.bookings.new',
    meta: {
      authRequired: true,
      permissions: ['create_hosting_booking']
    },
    component: () => import('./views/ops/hosting/booking/new'),
  },

  {
    path: '/operations/hosting/bookings/edit/:uid',
    name: 'ops.hosting.bookings.edit',
    meta: {
      authRequired: true,
      permissions: ['update_hosting_booking']
    },
    component: () => import('./views/ops/hosting/booking/edit'),
  },

  {
    path: '/operations/hosting/bookings/view/:uid',
    name: 'ops.hosting.bookings.view',
    meta: {
      authRequired: true,
      permissions: ['read_hosting_booking']
    },
    component: () => import('./views/ops/hosting/booking/display'),
  },

  {
    path: '/operations/hosting/planning',
    name: 'ops.hosting.planning',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/base/hierarchy/services/new'),
  },

  {
    path: '/operations/hosting/rooms_status',
    name: 'ops.hosting.rooms_status',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/base/hierarchy/services/new'),
  },

  {
    path: '/operations/restauration/consumptions',
    name: 'ops.restauration.consumptions',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/base/hierarchy/services/new'),
  },

  {
    path: '/operations/receptions',
    name: 'ops.receptions',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/ops/receptions/index'),
  },

  {
    path: '/operations/receptions/:uid',
    name: 'ops.receptions.display',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/ops/receptions/display'),
  },

  {
    path: '/operations/hse/',
    name: 'ops.hse.index',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/ops/hse/index'),
  },

  {
    path: '/operations/hse/:category',
    name: 'ops.hse.index.category',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/ops/hse/category/index'),
  },

  {
    path: '/operations/hse/:category/new',
    name: 'ops.hse.index.category.new',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/ops/hse/category/new'),
  },

  {
    path: '/settings',
    name: 'base.settings.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/index_beta'),
  },

  {
    path: '/settings/general',
    name: 'base.settings.general',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/general/index'),
  },


  {
    path: '/settings/dropdowns',
    name: 'base.settings.dropdowns.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/dropdowns/index'),
  },

  {
    path: '/settings/hse_criterias',
    name: 'base.settings.hse_criterias.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/hse_criterias/index'),
  },

  {
    path: '/settings/validation_levels',
    name: 'base.settings.validation_levels.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/validation_levels/index_beta'),
  },

  {
    path: '/settings/notifications',
    name: 'base.settings.notifications.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/notifications/index_beta'),
  },

  {
    path: '/settings/flow_and_ops',
    name: 'base.settings.flow_and_ops.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/flow/index_beta'),
  },

  {
    path: '/settings/technical',
    name: 'base.settings.technical.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/tech/index'),
  },






  {
    path: '/settings/contrats',
    name: 'base.settings.contrats.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/setting/contrats'),
  },
  {
    path: '/settings/hebergement',
    name: 'base.settings.hebergement.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/setting/hebergement'),
  },
  {
    path: '/settings/operations',
    name: 'base.settings.operations.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/setting/operations'),
  },
  {
    path: '/settings/requets',
    name: 'base.settings.requets.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/setting/requets'),
  },
  {
    path: '/settings/stock',
    name: 'base.settings.stock.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/setting/stock'),
  },
  {
    path: '/settings/techniques',
    name: 'base.settings.techniques.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/setting/techniques'),
  },
  {
    path: '/settings/prestations',
    name: 'base.settings.prestations.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/index'),
  },
  {
    path: '/settings/prestations/restauration/typeRepas',
    name: 'base.settings.prestations.restauration.typeRepas.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/type_repas/index'),
  },
  {
    path: '/settings/prestations/restauration/typeRepas/new',
    name: 'base.settings.prestations.restauration.typeRepas.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/type_repas/new'),
  },

  {
    path: '/settings/prestations/restauration/typeRepas/edit/:uid',
    name: 'base.settings.prestations.restauration.typeRepas.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/type_repas/edit'),
  },
  {
    path: '/settings/prestations/restauration/service',
    name: 'base.settings.prestations.restauration.service.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/service/index'),
  },
  {
    path: '/settings/prestations/restauration/service/new',
    name: 'base.settings.prestations.restauration.service.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/service/new'),
  },

  {
    path: '/settings/prestations/restauration/service/edit/:uid',
    name: 'base.settings.prestations.restauration.service.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/service/edit'),
  },
  {
    path: '/settings/prestations/restauration/lieuRepas',
    name: 'base.settings.prestations.restauration.lieuRepas.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/lieuRepas/index'),
  },
  {
    path: '/settings/prestations/restauration/lieuRepas/new',
    name: 'base.settings.prestations.restauration.lieuRepas.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/lieuRepas/new'),
  },

  {
    path: '/settings/prestations/restauration/lieuRepas/edit/:uid',
    name: 'base.settings.prestations.restauration.lieuRepas.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/lieuRepas/edit'),
  },

  {
    path: '/settings/prestations/restauration/periodeRepas',
    name: 'base.settings.prestations.restauration.periodeRepas.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/periodeRepas/index'),
  },
  {
    path: '/settings/prestations/restauration/periodeRepas/new',
    name: 'base.settings.prestations.restauration.periodeRepas.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/periodeRepas/new'),
  },

  {
    path: '/settings/prestations/restauration/periodeRepas/edit/:uid',
    name: 'base.settings.prestations.restauration.periodeRepas.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/restauration/periodeRepas/edit'),
  },
  {
    path: '/settings/prestations/hebergement/type',
    name: 'base.settings.prestations.hebergement.type.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/hebergement/type/index'),
  },
  {
    path: '/settings/prestations/hebergement/type/new',
    name: 'base.settings.prestations.hebergement.type.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/hebergement/type/new'),
  },

  {
    path: '/settings/prestations/hebergement/type/edit/:uid',
    name: 'base.settings.prestations.hebergement.type.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/hebergement/type/edit'),
  },
  {
    path: '/settings/prestations/maintenance/type',
    name: 'base.settings.prestations.maintenance.type.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/maintenance/type/index'),
  },
  {
    path: '/settings/prestations/maintenance/type/new',
    name: 'base.settings.prestations.maintenance.type.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/maintenance/type/new'),
  },

  {
    path: '/settings/prestations/maintenance/type/edit/:uid',
    name: 'base.settings.prestations.maintenance.type.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/maintenance/type/edit'),
  },
  {
    path: '/settings/prestations/operations3D/type',
    name: 'base.settings.prestations.operations3D.type.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/operations3D/type/index'),
  },
  {
    path: '/settings/prestations/operations3D/type/new',
    name: 'base.settings.prestations.operations3D.type.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/operations3D/type/new'),
  },

  {
    path: '/settings/prestations/operations3D/type/edit/:uid',
    name: 'base.settings.prestations.operations3D.type.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/operations3D/type/edit'),
  },
  {
    path: '/settings/prestations/diverses/type',
    name: 'base.settings.prestations.diverses.type.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/diverses/type/index'),
  },
  {
    path: '/settings/prestations/diverses/type/new',
    name: 'base.settings.prestations.diverses.type.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/diverses/type/new'),
  },

  {
    path: '/settings/prestations/diverses/type/edit/:uid',
    name: 'base.settings.prestations.diverses.type.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/diverses/type/edit'),
  },
  {
    path: '/settings/prestations/escpaceVert/type',
    name: 'base.settings.prestations.escpaceVert.type.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/escpaceVert/type/index'),
  },
  {
    path: '/settings/prestations/escpaceVert/type/new',
    name: 'base.settings.prestations.escpaceVert.type.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/escpaceVert/type/new'),
  },

  {
    path: '/settings/prestations/escpaceVert/type/edit/:uid',
    name: 'base.settings.prestations.escpaceVert.type.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/prestations/escpaceVert/type/edit'),
  },
  {
    path: '/settings/company',
    name: 'settings.company',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/company'),
  },
  {
    path: '/settings/lifebases',
    name: 'settings.lifebases',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/baselifes'),
  },
  {
    path: '/settings/lifebases/new',
    name: 'settings.lifebases.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/baselifes/new'),
  },
  {
    path: '/settings/lifebases/show',
    name: 'settings.lifebases.show',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/baselifes/show'),
  },

  {
    path: '/settings/reports',
    name: 'settings.reports',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/reports'),
  },
  {
    path: '/settings/maintenance/index',
    name: 'settings.maintenance.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/maintenance/index'),
  },
  {
    path: '/settings/maintenance/new',
    name: 'settings.maintenance.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/maintenance/type_maintenance'),
  },
  {
    path: '/settings/maintenance/edit/:uid',
    name: 'settings.maintenance.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/maintenance/edit'),
  },


  {
    path: '/settings/personnel/index',
    name: 'settings.personnel.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/personnel/index'),
  },
  {
    path: '/settings/personnel/new',
    name: 'settings.personnel.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/personnel/type_personnel'),
  },
  {
    path: '/settings/personnel/edit/:uid',
    name: 'settings.personnel.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/personnel/edit'),
  },

  {
    path: '/settings/unit_types',
    name: 'settings.unit_types.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/unit_types/index'),
  },

  {
    path: '/settings/rooms_types',
    name: 'settings.rooms_types.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/rooms_types/index'),
  },

  {
    path: '/settings/rooms_types/new',
    name: 'settings.rooms_types.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/rooms_types/new'),
  },

  {
    path: '/settings/rooms_types/edit/:uid',
    name: 'settings.rooms_types.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/rooms_types/edit'),
  },

  {
    path: '/settings/equipment_types',
    name: 'settings.equipment_types.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_types/index'),
  },

  {
    path: '/settings/equipment_types/new',
    name: 'settings.equipment_types.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_types/new'),
  },

  {
    path: '/settings/equipment_types/edit/:uid',
    name: 'settings.equipment_types.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_types/edit'),
  },

  {
    path: '/settings/equipment_brands',
    name: 'settings.equipment_brands.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_brands/index'),
  },

  {
    path: '/settings/equipment_brands/new',
    name: 'settings.equipment_brands.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_brands/new'),
  },

  {
    path: '/settings/equipment_brands/edit/:uid',
    name: 'settings.equipment_brands.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_brands/edit'),
  },

  {
    path: '/settings/equipment_categories',
    name: 'settings.equipment_categories.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_categories/index'),
  },

  {
    path: '/settings/equipment_categories/new',
    name: 'settings.equipment_categories.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_categories/new'),
  },

  {
    path: '/settings/equipment_categories/edit/:uid',
    name: 'settings.equipment_categories.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/equipment_categories/edit'),
  },

  {
    path: '/settings/penalty_types',
    name: 'settings.penalty_types.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/penalty_types/index'),
  },

  {
    path: '/settings/penalty_types/new',
    name: 'settings.penalty_types.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/penalty_types/new'),
  },

  {
    path: '/settings/penalty_types/edit/:uid',
    name: 'settings.penalty_types.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/penalty_types/edit'),
  },

  {
    path: '/settings/warranty_types',
    name: 'settings.warranty_types.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/warranty_types/index'),
  },

  {
    path: '/settings/warranty_types/new',
    name: 'settings.warranty_types.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/warranty_types/new'),
  },

  {
    path: '/settings/warranty_types/edit/:uid',
    name: 'settings.warranty_types.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/warranty_types/edit'),
  },
  {
    path: '/settings/contrat_type',
    name: 'settings.contrat_type.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/contrat_type/index'),
  },

  {
    path: '/settings/contrat_type/new',
    name: 'settings.contrat_type.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/contrat_type/new'),
  },

  {
    path: '/settings/contrat_type/edit/:uid',
    name: 'settings.contrat_type.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/contrat_type/edit'),
  },

  {
    path: '/settings/uom',
    name: 'settings.uom.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/uom/index'),
  },

  {
    path: '/settings/uom/new',
    name: 'settings.uom.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/uom/new'),
  },

  {
    path: '/settings/uom/edit/:uid',
    name: 'settings.uom.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/uom/edit'),
  },

  {
    path: '/master_data/referentiels/stock_management/product_category',
    name: 'settings.product_category.index',
    meta: {
      authRequired: true,
      permissions: ["create_product_categ",
                    "edit_product_categ",
                    "delete_product_categ",
                    "view_product_categ",
                    "list_product_categ"
                  ]
    },
    component: () => import('./views/base/settings/product_categories/index'),
  },

  {
    path: '/settings/product_category/new',
    name: 'settings.product_category.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/product_categories/new'),
  },

  {
    path: '/settings/product_category/edit/:uid',
    name: 'settings.product_category.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/product_categories/edit'),
  },

  {
    path: '/master_data/referentiels/stock_management/warehouses',
    name: 'settings.warehouses.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/warehouses/index'),
  },

  {
    path: '/master_data/referentiels/extreme_business',
    name: 'master_data.referentiels.extreme_business',
    meta: {
      authRequired: true,
      permissions: [
        'create_supplier', 'edit_supplier', 'delete_supplier', 'view_supplier', 'list_supplier',
        "create_extc","update_extc","read_extc","delete_extc","list_extc",
        "create_ie",
        "update_ie",
        "read_ie",
        "delete_ie",
        "list_ie",
      ]
    },
    component: () => import('./views/master_data/extreme_business'),
  },
  {
    path: '/master_data/referentiels/zoo',
    name: 'master_data.referentiels.zoo.index',
    meta: {
      authRequired: true,
      permissions: ['create_animal',
                    'edit_animal',
                    'delete_animal',
                    'view_animal',
                    'list_animal'
                  ]
    },
    component: () => import('./views/master_data/zoo/index'),
  },
  {
    path: '/master_data/referentiels/plantarium',
    name: 'master_data.referentiels.plantarium.index',
    meta: {
      authRequired: true,
      permissions: [
                    'create_plant',
                    'edit_plant',
                    'delete_plant',
                    'view_plant',
                    'list_plant'
                  ]
    },
    component: () => import('./views/master_data/plantarium/index'),
  },

  {
    path: '/settings/warehouses/new',
    name: 'settings.warehouses.new',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/warehouses/new'),
  },

  {
    path: '/settings/warehouses/edit/:uid',
    name: 'settings.warehouses.edit',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/warehouses/edit'),
  },

  {
    path: '/settings/users/edit/:uid',
    name: 'settings.users.edit',
    meta: {
      authRequired: true,
      permissions: ['users_crud']
    },
    component: () => import('./views/base/settings/users/edit'),
  },

  {
    path: '/settings/users',
    name: 'settings.users.index',
    meta: {
      authRequired: true,
      permissions: ['users_crud']
    },
    component: () => import('./views/base/settings/users/index'),
  },

  {
    path: '/settings/users/new',
    name: 'settings.users.new',
    meta: {
      authRequired: true,
      permissions: ['users_crud']
    },
    component: () => import('./views/base/settings/users/new'),
  },

  {
    path: '/settings/access_log',
    name: 'settings.access_log.index',
    meta: {
      authRequired: true,
      permissions: ['system_settings_crud']
    },
    component: () => import('./views/base/settings/beta/access_log/index'),
  },

  {
    path: '/settings/roles',
    name: 'settings.roles.index',
    meta: {
      authRequired: true,
      permissions: ['roles_crud']
    },
    component: () => import('./views/base/settings/roles/index'),
  },

  {
    path: '/settings/roles/new',
    name: 'settings.roles.new',
    meta: {
      authRequired: true,
      permissions: ['roles_crud']
    },
    component: () => import('./views/base/settings/roles/new'),
  },

  {
    path: '/settings/roles/edit/:uid',
    name: 'settings.roles.edit',
    meta: {
      authRequired: true,
      permissions: ['roles_crud']
    },
    component: () => import('./views/base/settings/roles/edit'),
  },

  {
    path: '/settings/roles/:uid/permissions/:name',
    name: 'settings.roles.permissions',
    meta: {
      authRequired: true,
      permissions: ['roles_crud']
    },
    component: () => import('./views/base/settings/roles/permission'),
  },
  {
    path: '/settings/user/profile',
    name: 'settings.user.profile',
    meta: {
      authRequired: true,
      // permissions: ['roles_crud']
    },
    component: () => import('./views/account/profile'),
  },

  {
    path: '/settings/user/notifications',
    name: 'settings.user.notifications',
    meta: {
      authRequired: true,
      // permissions: ['roles_crud']
    },
    component: () => import('./views/account/notifications'),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('./views/auth/login_beta'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'default' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch('auth/logOut')
        } else {
          store.dispatch('authfack/logout')
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'default' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },

  {
    path: '/unauthorized',
    name: '401',
    component: require('./views/utility/401').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/dashboard/saas',
    name: 'saas-dashboard',
    meta: { authRequired: true },
    component: () => import('./views/dashboards/saas/index')
  },
  {
    path: '/dashboard/crypto',
    name: 'crypto-dashboard',
    meta: { authRequired: true },
    component: () => import('./views/dashboards/crypto/index')
  },

]
