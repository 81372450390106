var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-pages my-5 pt-5" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "text-center mb-5" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: require("@/assets/images/camplus_logo.png"),
                width: "220",
                alt: ""
              }
            }),
            _vm._v(" "),
            _c("br"),
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: require("@/assets/images/unauthorized.png"),
                width: "220",
                alt: ""
              }
            }),
            _c("h4", { staticClass: "text-uppercase" }, [
              _vm._v("Accès non autorisé")
            ]),
            _c(
              "div",
              { staticClass: "mt-3 text-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { tag: "a", to: { name: "dashboard" } }
                  },
                  [
                    _c("i", { staticClass: "fas fa-home" }),
                    _vm._v(" Page d'accueil")
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }