import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import VueToastr2 from 'vue-toastr-2';
import 'vue-toastr-2/dist/vue-toastr-2.min.css';
import "@/design/index.scss";
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
import '@/mixins/permissionsMixins';
import '@/mixins/helpers';
import VueModal from '@kouts/vue-modal';
import VueAdsTable from 'vue-ads-table-tree';
import '@kouts/vue-modal/dist/vue-modal.css';
import store from '@/state/store'
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import SmartTable from 'vuejs-smart-table';
import VueLoading from 'vue-loading-overlay';
import Loading from 'vue-loading-overlay';
import StarRating from 'vue-star-rating'
import 'vue-loading-overlay/dist/vue-loading.css';
import Popover from 'vue-js-popover';
// import AwesomeVueStarRating from 'awesome-vue-star-rating'
import App from './App.vue'
import money from 'v-money'
import { initFirebaseBackend } from './authUtils'
import i18n from './i18n'
import http from './http';
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);
require('@/state/subscriber');
// store.dispatch('apiAuth/attempt', localStorage.getItem('token'));
import { configureFakeBackend } from './helpers/fake-backend';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import VueFormulate from '@braid/vue-formulate'
import '@braid/vue-formulate/dist/snow.css';
Vue.use(VueFormulate)
import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import tinymce from 'vue-tinymce-editor';
Vue.component('tinymce', tinymce)
Vue.component('Modal', VueModal);
Vue.component('Loading', Loading);
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(Popover);
Vue.use(VueIziToast);
Vue.use(VueLoading);
Vue.use(VueRouter)
// Vue.use(AwesomeVueStarRating);
Vue.use(datePicker);
Vue.use(SmartTable);
Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false
window.toastr = require('vue-toastr-2')
Vue.use(VueToastr2)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(VueAdsTable);
Vue.use(money, {precision: 4})
Vue.prototype.$http = http;
Vue.prototype.$toast = VueIziToast;
Vue.prototype.$vtoast = VueToastr2;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.component('StarRating', StarRating)
import permissions from './plugins/permissions';
import { abilitiesPlugin } from '@casl/vue';
Vue.use(abilitiesPlugin, permissions);





// echo TST

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: process.env.VUE_APP_WEBSOCKET_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKET_API,
//   wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
//   forceTLS: false,
//   disableStats: false,
// });





new Vue({
  router,
  store,
  i18n,
  VueIziToast,
  render: h => h(App)
}).$mount('#app')
