 <script>
import appConfig from "@/app.config";

export default {
  page: {
    title: "Page non trouvée",
    meta: [{ name: "description", content: appConfig.description }]
  },
};
</script>
 <template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <img src="@/assets/images/camplus_logo.png" width="220" alt class="img-fluid" /> <br>
            <img src="@/assets/images/page-not-found.png" width="220" alt class="img-fluid" />
            <h4 class="text-uppercase mt-3">Page non trouvée</h4>
            <div class="mt-3 text-center">
              <router-link tag="a" class="btn btn-primary" :to="{ name: 'dashboard' }"> <i class="fas fa-home"></i> Page d'accueil</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
