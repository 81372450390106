
import store from '@/state/store';
import { defineAbility } from '@casl/ability';

export default defineAbility((can) => {
    var userPermissions = store.getters['apiAuth/permisions'];
    if(userPermissions != null){
        userPermissions.forEach(permission => {
            can(permission);
        });
    }
});