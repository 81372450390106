import Vue from 'vue'

Vue.mixin({
    methods: {
        canAny(permissions) {
            var isAble = false;
            permissions.forEach(permission => {
                if(this.$can(permission)){
                    isAble = true;
                }
            });
            return isAble;
        },
    }
  })