// import state from '@/state';
import store from './store';
import http from '../http';
store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'apiAuth/SET_TOKEN':
            if(mutation.payload){
                http.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
                localStorage.setItem('token', mutation.payload);
            }
            else{
                http.defaults.headers.common['Authorization'] = null;
                localStorage.removeItem('token');
            }
        break;
    
        default:
            break;
    }
});